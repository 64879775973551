import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faHome,
  faUser,
  faAdd,
  faRemove,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faPhone,
  faTrash,
  faFile,
  faCircleArrowDown,
  faSpinner,
  faBars,
  faPlus,
  faMinus,
  faCalendar,
  faChurch,
  faCheckCircle,
  faUserMinus,
  faAirFreshener
} from "@fortawesome/free-solid-svg-icons";
library.add(
  faHome, 
  faUser,
  faAdd, 
  faRemove,
  faUserPlus, 
  faSignInAlt, 
  faSignOutAlt, 
  faPhone, 
  faTrash, 
  faFile, 
  faCircleArrowDown, 
  faSpinner, 
  faBars, 
  faPlus, 
  faMinus,
  faCalendar,
  faChurch,
  faUserMinus,
  faCheckCircle,
  faAirFreshener);
export { FontAwesomeIcon };
